import React, { useState } from 'react';
import './SubCluster.less';
import Icon, { InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Divider, Space, Tooltip, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import SubClusterList from './SubClusterList/SubClusterList';
import { ReactComponent as AnalyticsIcon } from 'images/analytics.svg';
import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';
import usePlatformActionsSupport, { PLATFORM_ACTIONS_KEYS } from 'hooks/usePlatformActionsSupport';
import SelfManagedClusterModal from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SelfManagedClusterModal/SelfManagedClusterModal';
// import { sum } from 'utils';
import { UserData } from 'types/user';

interface SubClusterProps {
  instance: Instance;
  service: Service;
  user: UserData;
}
function SubCluster({ instance, service, user }: SubClusterProps) {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  const instanceStatus = getClusterStatus(instance, service);

  const subClustersLength = instance.subClusters.length;

  const showCreate = instance.clusterPolicy.subClusterPolicy.includes('create');
  const { isPlatformActionSupported: isSubclusterSupported } = usePlatformActionsSupport({
    currentPlatform: instance.platform,
    userPlatformActions: user?.platformActions,
    platformKey: PLATFORM_ACTIONS_KEYS.subcluster,
  });

  const createButtonUi =
    showCreate &&
    (instanceStatus === 'running' ? (
      <Tooltip
        placement="left"
        title={
          !isSubclusterSupported ? (
            <FormattedMessage
              id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
              values={{
                action: intl.formatMessage({
                  id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.subcluster',
                }),
              }}
            />
          ) : (
            ''
          )
        }
      >
        <Button
          disabled={!isSubclusterSupported}
          size="small"
          icon={<PlusOutlined />}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <FormattedMessage id="createNewSubClusterModal.createButton" />
        </Button>
      </Tooltip>
    ) : (
      <Tooltip title="Connect your cluster to create a new Self-Managed Cluster">
        <Button size="small" icon={<PlusOutlined />} disabled>
          <FormattedMessage id="createNewSubClusterModal.createButton" />
        </Button>
      </Tooltip>
    ));

  return (
    <div className="SubCluster">
      <div className="SubCluster__icon-wrapper">
        <Icon component={AnalyticsIcon} />
      </div>

      <div className="SubCluster__content-wrapper">
        <div className="SubCluster__content-title-wrapper">
          <div className="SubCluster__content-title">
            <h2>Self-Managed Cluster</h2>
            <p>
              Create Self-Managed Clusters for dedicated groups of users (who might have different priorities) within
              your cluster to isolate work environment.
            </p>
          </div>

          <div className="SubCluster__content-action">
            <Space>
              {createButtonUi}
              {showCreate && <Divider type="vertical" />}
              <Typography.Text className="SubCluster__items-count">{subClustersLength} Items</Typography.Text>
            </Space>
          </div>
        </div>

        <SubClusterList instance={instance} createButtonUi={createButtonUi} />

        <p className="SubCluster__hint">
          <InfoCircleFilled /> Self-Managed Cluster use the main cluster loader nodes. If you need to improve the
          running and loading of multiple parallel workloads, configure loader nodes from{' '}
          <Link to={`/clusters/${instance.name}?tab=configurations`}>Configurations</Link>.
        </p>
      </div>

      <SelfManagedClusterModal showModal={showModal} onCancel={() => setShowModal(false)} instance={instance} />
    </div>
  );
}

export default SubCluster;
