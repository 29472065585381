import { LinkOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import copy from 'clipboard-copy';
import { useHistory } from 'react-router-dom';
import { ReactComponent as OpenIcon } from 'images/open-icon.svg';
import { Instance, SelfManagedClusterTypes, SubCluster } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';
import usePlatformActionsSupport, { PLATFORM_ACTIONS_KEYS } from 'hooks/usePlatformActionsSupport';
import useSubCluster from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubClusterList/useSubCluster';

type SelfManagedClustersListLinksActionsProps = {
  subCluster: SubCluster;
  instance: Instance;
};
function SelfManagedClustersListLinksActions({ subCluster, instance }: SelfManagedClustersListLinksActionsProps) {
  const { getSubClusterState } = useSubCluster();

  const isClusterRunning = instance.status === 'running';
  const { user } = useContext(SessionContext);
  const { isPlatformActionSupported: isSubClusterSupported } = usePlatformActionsSupport({
    currentPlatform: instance.platform,
    userPlatformActions: user?.platformActions,
    platformKey: PLATFORM_ACTIONS_KEYS.subcluster,
  });

  const [copping, setCopping] = useState(false);
  const state = getSubClusterState(subCluster);
  const isSubClusterConnected = state === 'running';
  const history = useHistory();

  useEffect(() => {
    if (copping) {
      const id = setTimeout(() => setCopping(false), 1000);
      return () => clearTimeout(id);
    }
  }, [copping]);

  const additionalTooltip = !isClusterRunning
    ? ' : Cluster Must be running'
    : !isSubClusterSupported
    ? ' : You can’t make actions on Self-Managed Cluster'
    : !isSubClusterConnected
    ? ' : Self-Managed Cluster Must be running!'
    : '';

  const isButtonDisabled = !isClusterRunning || !isSubClusterSupported || !isSubClusterConnected;

  if (subCluster.configs && JSON.parse(subCluster.configs).fallbackAnalytics) return null;
  return (
    <div className="SubClusterActions">
      <Tooltip
        title={
          subCluster.type === SelfManagedClusterTypes.sqlx ? 'SQLX Configuration' : `Open Incorta` + additionalTooltip
        }
      >
        {subCluster.type === SelfManagedClusterTypes.sqlx ? (
          <Button
            type="text"
            size="small"
            icon={<ArrowRightIcon />}
            onClick={() => {
              history.push(`/clusters/${instance.name}?tab=configurations#cluster-incorta-x`);
            }}
          />
        ) : (
          <Button
            type="text"
            size="small"
            icon={<OpenIcon />}
            disabled={isButtonDisabled}
            onClick={(e) => {
              if (state !== 'running') {
                e.preventDefault();
              }
              window.open(subCluster.url, '_blank');
            }}
          />
        )}
      </Tooltip>

      {subCluster.type !== SelfManagedClusterTypes.sqlx ? (
        <Tooltip title={copping ? 'Copied' : `Copy Incorta URL${additionalTooltip}`}>
          <Button
            type="text"
            size="small"
            icon={<LinkOutlined />}
            onClick={() => {
              copy(subCluster.url);
              setCopping(true);
            }}
            disabled={isButtonDisabled}
          />
        </Tooltip>
      ) : null}
    </div>
  );
}

export default SelfManagedClustersListLinksActions;
