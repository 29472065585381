import { Button, Modal } from 'antd';
import * as React from 'react';

import './clusterServiceControlErrorModal.less';

interface ClusterServiceControlErrorModalProps {
  serviceName: string;
  type: 'error' | 'warning';
  errors: string[];
  visible: boolean;
  close: () => void;
}

export async function copyToClipboard(copiedValue: string) {
  if (!navigator.clipboard) {
    return copyCodeToClipboardFallback(copiedValue);
  }
  return copyToClipboardMain(copiedValue);
}

function copyCodeToClipboardFallback(copiedValue: string) {
  var textArea = document.createElement('textarea');
  textArea.value = copiedValue;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}
async function copyToClipboardMain(copiedValue: string) {
  await navigator.clipboard.writeText(copiedValue);
}

const ClusterServiceControlErrorModal = ({
  serviceName,
  type,
  errors,
  visible,
  close,
}: ClusterServiceControlErrorModalProps) => {
  const modalTitle = type === 'error' ? `${serviceName} failed to start` : `${serviceName} is in a warning state`;

  const renderModalFooter = () => (
    <div className="modal-footer-container">
      <Button
        onClick={() => {
          copyToClipboard(errors.map((error) => `${error}`).join('\n\n'));
        }}
      >
        Copy to Clipboard
      </Button>

      <Button type="primary" onClick={close}>
        Ok
      </Button>
    </div>
  );

  return (
    <Modal title={modalTitle} footer={renderModalFooter()} visible={visible} onCancel={close}>
      <div className="modal-body-container">
        <p>{type === 'error' ? 'ERROR' : 'WARNING(s)'} </p>
        <div className="errors-content">
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ClusterServiceControlErrorModal;
