import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { MinusCircleOutlined, PlayCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import {
  canForceStartServiceList,
  canRestartServiceList,
  canStartServiceList,
  canStopServiceList,
} from '../../ClusterServicesControlHelper/useClusterServicesControlHelper';
import useServiceAction from './useServiceAction';
import { SessionContext } from 'auth/SessionProvider';
import TableRowActionButton from 'components/reusables/TableRowActionButton/TableRowActionButton';
import { ClusterAction, ObservabilityServiceStatus, ServiceStatus, ServiceToRestart } from 'types/cluster';

function ClusterServicesControlActions({
  status,
  instanceId,
  serviceNode,
  nodeName,
  tempService,
  isSubCluster = false,
  isSubClusterConnected,
}: {
  status: ServiceStatus | ObservabilityServiceStatus;
  instanceId: string;
  serviceNode: ServiceToRestart;
  nodeName: string;
  tempService: boolean;
  isSubCluster?: boolean;
  isSubClusterConnected?: boolean;
}) {
  const { user } = useContext(SessionContext);
  let isTrialUser = user?.role?.id === 1;
  isTrialUser = false;

  const { triggeredAction, handleServiceRestartorStop, isChangingServiceState, isStopOrRestartServiceApiSuccess } =
    useServiceAction({
      instanceId,
      serviceNode,
    });

  const isSubClusterAndDisconnected = isSubCluster && !isSubClusterConnected;
  const isStopBtnLoading = triggeredAction === ClusterAction.STOP && isChangingServiceState;
  const isStopBtnDisabled =
    isTrialUser ||
    tempService ||
    isSubClusterAndDisconnected ||
    (triggeredAction === ClusterAction.RESTART && isChangingServiceState);
  const isRestartBtnLoading = triggeredAction === ClusterAction.RESTART && isChangingServiceState;

  const isForceStartBtnLoading = triggeredAction === ClusterAction.FORCE_RESTART && isChangingServiceState;
  const isRestartBtnDisabled =
    isTrialUser ||
    tempService ||
    isSubClusterAndDisconnected ||
    (triggeredAction === ClusterAction.STOP && isChangingServiceState);
  const isStartBtnLoading = triggeredAction === ClusterAction.START && isChangingServiceState;
  const isStartBtnDisabled =
    isTrialUser ||
    tempService ||
    isSubClusterAndDisconnected ||
    (triggeredAction === ClusterAction.START && isStopOrRestartServiceApiSuccess);

  return (
    <div className="cluster-services-control__actions-wrapper">
      {canStopServiceList.includes(status as ServiceStatus) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <TableRowActionButton
            loading={isStopBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.STOP,
                nodeName,
              })
            }
            icon={<MinusCircleOutlined />}
            disabled={isStopBtnDisabled}
          >
            {ClusterAction.STOP}
          </TableRowActionButton>
        </Tooltip>
      )}

      {canForceStartServiceList.includes(status as ServiceStatus) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <TableRowActionButton
            loading={isForceStartBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.FORCE_RESTART,
                nodeName,
              })
            }
            icon={<PlayCircleOutlined />}
            disabled={isRestartBtnDisabled}
          >
            {ClusterAction.START}
          </TableRowActionButton>
        </Tooltip>
      )}
      {canRestartServiceList.includes(status as ServiceStatus) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <TableRowActionButton
            loading={isRestartBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.RESTART,
                nodeName,
              })
            }
            icon={<RetweetOutlined />}
            disabled={isRestartBtnDisabled}
          >
            {ClusterAction.RESTART}
          </TableRowActionButton>
        </Tooltip>
      )}
      {canStartServiceList.includes(status as ServiceStatus) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <TableRowActionButton
            loading={isStartBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.START,
                nodeName,
              })
            }
            icon={<PlayCircleOutlined />}
            disabled={isStartBtnDisabled}
          >
            {ClusterAction.START}
          </TableRowActionButton>
        </Tooltip>
      )}
    </div>
  );
}

export default ClusterServicesControlActions;
