import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import ClusterServicesControlActions from '../ClusterServicesControlComponents/ClusterServicesControlActions/ClusterServicesControlActions';
import ClusterServicesControlStatusTag from '../ClusterServicesControlComponents/ClusterServicesControlStatusTag/ClusterServicesControlStatusTag';
import ClusterServiceTableHeaderOptions from '../ClusterServicesControlComponents/ClusterServiceTableHeaderOptions/ClusterServiceTableHeaderOptions';
import {
  Instance,
  ObservabilityServiceStatus,
  ServiceItem,
  ServiceStatus,
  ServiceToRestart,
  SubClusterServiceItem,
} from 'types/cluster';
import {
  statusTagBlueOngoing,
  statusTagGreenFinishedSuccessfully,
  statusTagGreyInfo,
  statusTagOrangeFinishedWithWarnings,
  statusTagOrangeOngoing,
  statusTagRedFinishedWithError,
} from 'utils/cluster';

export const statusTag = {
  [ServiceStatus.PROCESSING]: statusTagBlueOngoing,
  [ServiceStatus.STARTING_TENANT]: statusTagBlueOngoing,
  [ServiceStatus.STARTING_TENANTS]: statusTagBlueOngoing,
  [ServiceStatus.IS_STARTING]: statusTagBlueOngoing,
  [ServiceStatus.RUNNING]: statusTagGreenFinishedSuccessfully,
  [ServiceStatus.SLEEPING]: statusTagRedFinishedWithError,
  [ServiceStatus.STOPPING]: statusTagOrangeOngoing,
  [ServiceStatus.STOPPING_TENANT]: statusTagOrangeOngoing,
  [ServiceStatus.STOPPING_TENANTS]: statusTagOrangeOngoing,
  [ServiceStatus.NOT_RUNNING]: statusTagRedFinishedWithError,
  [ServiceStatus.ERROR]: statusTagRedFinishedWithError,
  [ServiceStatus.STARTUP_ERROR]: statusTagRedFinishedWithError,
  [ServiceStatus.NOT_RESPONDING]: statusTagRedFinishedWithError,
  [ServiceStatus.TERMINATED_UNEXPECTEDLY]: statusTagRedFinishedWithError,
  [ServiceStatus.NA]: statusTagGreyInfo,
  [ServiceStatus.FAILED_TO_START]: statusTagRedFinishedWithError,
  [ServiceStatus.STARTED_WITH_WARNINGS]: statusTagOrangeFinishedWithWarnings,
  [ServiceStatus.CREATING]: statusTagBlueOngoing,
  [ServiceStatus.DELETING]: statusTagOrangeOngoing,
} as const;

export const minServiceNodesCount = {
  analytics: 1,
  loader: 1,
  sqli: 1,
};
export const maxServiceNodesCount = {
  analytics: 10,
  loader: 10,
  sqli: 1,
};

export const cantShowSQLiTableList = [
  ServiceStatus.ERROR,
  ServiceStatus.STARTUP_ERROR,
  ServiceStatus.NOT_RESPONDING,
  ServiceStatus.TERMINATED_UNEXPECTEDLY,
  ServiceStatus.FAILED_TO_START,
  ServiceStatus.NA,
];

export const cantShowNotebookTableList = [
  ServiceStatus.ERROR,
  ServiceStatus.STARTUP_ERROR,
  ServiceStatus.NOT_RESPONDING,
  ServiceStatus.TERMINATED_UNEXPECTEDLY,
  ServiceStatus.FAILED_TO_START,
  ServiceStatus.NA,
];

export const canStartServiceList = [
  ServiceStatus.SLEEPING,
  ServiceStatus.STOPPING,
  ServiceStatus.STOPPING_TENANTS,
  ServiceStatus.NOT_RUNNING,
];

export const canStopServiceList = [
  ServiceStatus.PROCESSING,
  ServiceStatus.STARTING_TENANTS,
  ServiceStatus.STARTING_TENANT,
  ServiceStatus.IS_STARTING,
  ServiceStatus.RUNNING,
  ServiceStatus.STARTED_WITH_WARNINGS,
];

export const canRestartServiceList = [
  ServiceStatus.RUNNING,
  ServiceStatus.ERROR,
  ServiceStatus.STARTUP_ERROR,
  ServiceStatus.TERMINATED_UNEXPECTEDLY,
  ServiceStatus.FAILED_TO_START,
  ServiceStatus.NA,
];

export const canForceStartServiceList = [ServiceStatus.ERROR, ServiceStatus.NA];

const servicesCanHaveMultipleNode = [ServiceToRestart.ANALYTICS, ServiceToRestart.LOADER];

const serviceColumnProps = {
  name: ({ title }: { title: JSX.Element | string }) => ({
    title,
    dataIndex: 'node_name',
    key: 'node_name',
    width: '300px',
  }),
  status: ({ key, dataIndex }: { key: string; dataIndex: string }) => ({
    title: '',
    key,
    dataIndex,
  }),
  actions: () => ({
    dataIndex: 'actions',
    width: '200px',
    key: 'actions',
  }),
};

type useClusterServicesControlHelperProps = {
  instance: Instance;
  hasServiceStatusError: boolean;
  isClusterConnected: boolean;
};
function useClusterServicesControlHelper({
  instance,
  hasServiceStatusError,
  isClusterConnected,
}: useClusterServicesControlHelperProps) {
  const intl = useIntl();

  const [statusName] = useState(
    new Map<ServiceStatus, string>([
      [
        ServiceStatus.PROCESSING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.processing',
        }),
      ],
      [
        ServiceStatus.STARTING_TENANTS,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.startingTenants',
        }),
      ],
      [
        ServiceStatus.STARTING_TENANT,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.startingTenant',
        }),
      ],
      [
        ServiceStatus.IS_STARTING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.isStarting',
        }),
      ],
      [
        ServiceStatus.RUNNING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.running',
        }),
      ],
      [
        ServiceStatus.SLEEPING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.sleeping',
        }),
      ],
      [
        ServiceStatus.STOPPING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.stopping',
        }),
      ],
      [
        ServiceStatus.STOPPING_TENANTS,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.stoppingTenants',
        }),
      ],
      [
        ServiceStatus.STOPPING_TENANT,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.stoppingTenant',
        }),
      ],
      [
        ServiceStatus.NOT_RUNNING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.notRunning',
        }),
      ],
      [
        ServiceStatus.ERROR,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.error',
        }),
      ],
      [
        ServiceStatus.STARTUP_ERROR,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.startupError',
        }),
      ],
      [
        ServiceStatus.NOT_RESPONDING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.notResponding',
        }),
      ],
      [
        ServiceStatus.TERMINATED_UNEXPECTEDLY,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.TerminatedUnexpectedly',
        }),
      ],
      [
        ServiceStatus.NA,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.na',
        }),
      ],
      [
        ServiceStatus.FAILED_TO_START,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.failedToStart',
        }),
      ],
      [
        ServiceStatus.STARTED_WITH_WARNINGS,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.startedWithWarnings',
        }),
      ],
      [
        ServiceStatus.CREATING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.creating',
        }),
      ],
      [
        ServiceStatus.DELETING,
        intl.formatMessage({
          id: 'clusterConfiguration.clusterServicesControl.status.deleting',
        }),
      ],
    ])
  );

  const canControl = instance.status === 'running' && !instance?.authorizedRoles?.includes('developer');

  const analyticsColumns: ColumnsType<ServiceItem<'analytics'>> = [
    {
      ...serviceColumnProps.name({
        title: <FormattedMessage id="clusterConfiguration.clusterServicesControl.titles.analytics" />,
      }),
      render(value, record, index) {
        return (
          <FormattedMessage
            id="clusterConfiguration.clusterServicesControl.serviceNames.analytics"
            values={{ number: index + 1 }}
          />
        );
      },
    },
    {
      ...serviceColumnProps.status({
        key: 'status',
        dataIndex: 'status',
      }),
      render: (_, { status }) => (
        <ClusterServicesControlStatusTag
          serviceNode={ServiceToRestart.ANALYTICS}
          statusName={statusName}
          status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
          statusMessage={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusMessage : ''}
          warnings={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).warnings : []}
          error={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).error : ''}
        />
      ),
    },
    {
      ...serviceColumnProps.actions(),
      align: 'right',
      title() {
        if (!canControl) return null;
        return (
          <ClusterServiceTableHeaderOptions
            key={'analytics'}
            canControl={canControl}
            isClusterConnected={isClusterConnected}
            hasServiceStatusError={hasServiceStatusError}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.ANALYTICS}
            serviceType="analytics"
            clusterName={instance.name}
            minNodesCount={minServiceNodesCount.analytics}
            maxNodesCount={maxServiceNodesCount.analytics}
          />
        );
      },
      render(value, { status, node_name, name }, index) {
        if (!canControl) return null;
        return (
          <ClusterServicesControlActions
            status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.ANALYTICS}
            nodeName={node_name}
            tempService={node_name?.startsWith('__temp') || node_name?.startsWith('__toBeDeleted') ? true : false}
          />
        );
      },
    },
  ];

  const loaderColumns: ColumnsType<ServiceItem<'loader'>> = [
    {
      ...serviceColumnProps.name({
        title: <FormattedMessage id="clusterConfiguration.clusterServicesControl.titles.loader" />,
      }),
      render(value, record, index) {
        return (
          <FormattedMessage
            id="clusterConfiguration.clusterServicesControl.serviceNames.loader"
            values={{ number: index + 1 }}
          />
        );
      },
    },
    {
      ...serviceColumnProps.status({
        key: 'status',
        dataIndex: 'status',
      }),
      render: (_, { status }) => (
        <ClusterServicesControlStatusTag
          serviceNode={ServiceToRestart.LOADER}
          statusName={statusName}
          status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
          statusMessage={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusMessage : ''}
          warnings={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).warnings : []}
          error={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).error : ''}
        />
      ),
    },
    {
      ...serviceColumnProps.actions(),
      align: 'right',
      title() {
        if (!canControl) return null;
        return (
          <ClusterServiceTableHeaderOptions
            key={'loader'}
            canControl={canControl}
            isClusterConnected={isClusterConnected}
            hasServiceStatusError={hasServiceStatusError}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.LOADER}
            serviceType="loader"
            clusterName={instance.name}
            minNodesCount={minServiceNodesCount.loader}
            maxNodesCount={maxServiceNodesCount.loader}
          />
        );
      },
      render(value, { status, node_name, name }, index) {
        if (!canControl) return null;
        return (
          <ClusterServicesControlActions
            status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.LOADER}
            nodeName={node_name}
            tempService={node_name?.startsWith('__temp') || node_name?.startsWith('__toBeDeleted') ? true : false}
          />
        );
      },
    },
  ];

  const sqliColumns: ColumnsType<ServiceItem<'sqli'>> = [
    {
      ...serviceColumnProps.name({
        title: <FormattedMessage id="clusterConfiguration.clusterServicesControl.titles.sqli" />,
      }),
      render() {
        return <FormattedMessage id="clusterConfiguration.clusterServicesControl.serviceNames.sqli" />;
      },
    },
    {
      ...serviceColumnProps.status({
        key: 'status',
        dataIndex: 'status',
      }),
      render: (_, { status }) => <ClusterServicesControlStatusTag statusName={statusName} status={status} />,
    },
    {
      ...serviceColumnProps.actions(),
      align: 'right',
      render(value, { status, node_name, name }, index) {
        if (!canControl) return null;
        return (
          <ClusterServicesControlActions
            status={status}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.SQLI}
            nodeName={node_name}
            tempService={false}
          />
        );
      },
    },
  ];

  const notebookColumns: ColumnsType<ServiceItem<'notebook'>> = [
    {
      ...serviceColumnProps.name({
        title: <FormattedMessage id="clusterConfiguration.clusterServicesControl.titles.notebook" />,
      }),
      render() {
        return <FormattedMessage id="clusterConfiguration.clusterServicesControl.serviceNames.notebook" />;
      },
    },
    {
      ...serviceColumnProps.status({
        key: 'status',
        dataIndex: 'status',
      }),
      render: (_, { status }) => <ClusterServicesControlStatusTag statusName={statusName} status={status} />,
    },
    {
      ...serviceColumnProps.actions(),
      align: 'right',
      render(value, { status, node_name, name }, index) {
        if (!canControl) return null;
        return (
          <ClusterServicesControlActions
            status={status}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.NOTEBOOK}
            nodeName={node_name}
            tempService={false}
          />
        );
      },
    },
  ];

  const analyticsSubClusterColumns: ColumnsType<SubClusterServiceItem<'analytics-sub-cluster'>> = [
    {
      ...serviceColumnProps.name({
        title: <FormattedMessage id="clusterConfiguration.clusterServicesControl.titles.analyticsSubCluster" />,
      }),
      render(value, record, index) {
        return record.failover ? (
          <FormattedMessage
            id="clusterConfiguration.clusterServicesControl.serviceNames.analyticsSubClusterFailOver"
            values={{ number: index + 1 }}
          />
        ) : (
          <FormattedMessage
            id="clusterConfiguration.clusterServicesControl.serviceNames.analyticsSubCluster"
            values={{ number: index + 1 }}
          />
        );
      },
    },
    {
      ...serviceColumnProps.status({
        key: 'status',
        dataIndex: 'status',
      }),
      render: (_, { status }) => {
        return (
          <ClusterServicesControlStatusTag
            serviceNode={ServiceToRestart.ANALYTICS}
            statusName={statusName}
            status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
            statusMessage={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusMessage : ''}
            warnings={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).warnings : []}
            error={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).error : ''}
          />
        );
      },
    },
    {
      ...serviceColumnProps.actions(),
      align: 'right',
      title() {
        if (!canControl) return null;
        return (
          <Link to={`/clusters/${instance.name}?tab=self-managed-clusters`}>
            <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.configureAnalyticsSubCluster" />
          </Link>
        );
      },
      render(value, { status, subclusterStatus, node_name, name }, index) {
        if (!canControl) return null;
        return (
          <ClusterServicesControlActions
            status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.ANALYTICS}
            nodeName={node_name}
            tempService={false}
            isSubCluster={true}
            isSubClusterConnected={subclusterStatus === 'running' || subclusterStatus === 'loading'}
          />
        );
      },
    },
  ];

  const loaderSubClusterColumns: ColumnsType<SubClusterServiceItem<'loader-sub-cluster'>> = [
    {
      ...serviceColumnProps.name({
        title: <FormattedMessage id="clusterConfiguration.clusterServicesControl.titles.loaderSubCluster" />,
      }),
      render(value, record, index) {
        return (
          <FormattedMessage
            id="clusterConfiguration.clusterServicesControl.serviceNames.loaderSubCluster"
            values={{ number: index + 1 }}
          />
        );
      },
    },
    {
      ...serviceColumnProps.status({
        key: 'status',
        dataIndex: 'status',
      }),
      render: (_, { status }) => (
        <ClusterServicesControlStatusTag
          serviceNode={ServiceToRestart.LOADER}
          statusName={statusName}
          status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
          statusMessage={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusMessage : ''}
          warnings={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).warnings : []}
          error={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).error : ''}
        />
      ),
    },
    {
      ...serviceColumnProps.actions(),
      align: 'right',
      render(value, { status, node_name, name }, index) {
        if (!canControl) return null;
        return (
          <ClusterServicesControlActions
            status={instance.observabilityNewUI ? (status as ObservabilityServiceStatus).statusType : status}
            instanceId={instance.id}
            serviceNode={ServiceToRestart.LOADER}
            nodeName={node_name}
            tempService={false}
          />
        );
      },
    },
  ];

  return {
    statusName,
    analyticsColumns,
    loaderColumns,
    sqliColumns,
    notebookColumns,
    analyticsSubClusterColumns,
    loaderSubClusterColumns,
    servicesCanHaveMultipleNode,
  };
}

export default useClusterServicesControlHelper;
