import React, { useState } from 'react';
import './AddRemoveClusterServicePopconfirm.less';
import { InputNumber, Popconfirm, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { useMutateClusterScale } from 'hooks/cluster';

const addServiceLabel = new Map<string, JSX.Element>([
  [
    'analytics',
    <FormattedMessage
      id="clusterConfiguration.clusterServicesControl.headerOptions.addServices"
      values={{
        serviceName: <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.analytics" />,
      }}
    />,
  ],
  [
    'loader',
    <FormattedMessage
      id="clusterConfiguration.clusterServicesControl.headerOptions.addServices"
      values={{
        serviceName: <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.loader" />,
      }}
    />,
  ],
]);

const deleteServiceLabel = new Map<string, JSX.Element>([
  [
    'analytics',
    <FormattedMessage
      id="clusterConfiguration.clusterServicesControl.headerOptions.deleteServices"
      values={{
        serviceName: <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.analytics" />,
      }}
    />,
  ],
  [
    'loader',
    <FormattedMessage
      id="clusterConfiguration.clusterServicesControl.headerOptions.deleteServices"
      values={{
        serviceName: <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.loader" />,
      }}
    />,
  ],
]);

type AddRemoveClusterServicePopconfirmProps = {
  serviceCount: number;
  clusterName: string;
  serviceType: 'analytics' | 'loader' | 'sqli';
  popoverType: 'add' | 'delete';
  minNodesCount: number;
  maxNodesCount: number;
  placement: TooltipPlacement;
  confirmLoading: boolean;
  setConfirmLoading: any;
  isAddingDisabled: boolean;
};
function AddRemoveClusterServicePopconfirm({
  serviceCount,
  clusterName,
  serviceType,
  popoverType,
  minNodesCount,
  maxNodesCount,
  placement,
  confirmLoading,
  setConfirmLoading,
  isAddingDisabled,
  children,
}: React.PropsWithChildren<AddRemoveClusterServicePopconfirmProps>) {
  const intl = useIntl();
  const {
    mutateAsync: mutateClusterScale,
    // eslint-disable-next-line
    isLoading: isClusterScaleAPILoading,
  } = useMutateClusterScale({
    clusterName,
  });

  async function confirm(e: any) {
    setConfirmLoading(true);

    let newNodesTotalCount;
    if (popoverType === 'add') {
      newNodesTotalCount = serviceCount + nodesNumber;
    } else if (popoverType === 'delete') {
      newNodesTotalCount = serviceCount - nodesNumber;
    }

    const successMessage = intl.formatMessage(
      {
        id: 'clusterConfiguration.clusterServicesControl.headerOptions.messages.addDeleteSuccess',
      },
      {
        nodesNumber,
        serviceType,
        plural: nodesNumber > 1 ? 's' : '',
        operation: intl.formatMessage({
          id:
            popoverType === 'add'
              ? 'clusterConfiguration.clusterServicesControl.headerOptions.messages.operationAdded'
              : 'clusterConfiguration.clusterServicesControl.headerOptions.messages.operationDeleted',
        }),
      }
    );

    try {
      const { data } = await mutateClusterScale({
        [`${serviceType}Replicas`]: newNodesTotalCount,
      });
      if (data?.status?.toString?.()?.startsWith('2')) {
        // await new Promise(resolve => {
        //   setTimeout(() => {
        //     resolve(null);
        //   }, 4000);
        // });

        message.info(successMessage);
      }
    } catch (error) {
      //
    } finally {
      setConfirmLoading(false);
    }

    // e.preventDefault();
  }

  const cancel = (e: any) => {
    console.log(e);
  };

  const [nodesNumber, setNodesNumber] = useState(1);
  function handleChangeNodeCount(value: string | number | null | undefined) {
    if (Number(value) >= minNodesCount && Number(value) <= maxNodesCount) setNodesNumber(value as number);
  }

  let myMax = 1;
  if (serviceType === 'analytics' || serviceType === 'loader') {
    if (popoverType === 'add') {
      myMax = maxNodesCount - serviceCount;
    } else if (popoverType === 'delete') {
      myMax = serviceCount - minNodesCount;
    }
  }

  async function handleChangePopConfirmVisibility(visible: boolean) {
    if (!visible) {
      setNodesNumber(1);
    }
  }

  return (
    <Popconfirm
      title={
        <div className="add-remove-cluster-service-popconfirm__wrapper">
          <label>
            {popoverType === 'add' ? addServiceLabel.get(serviceType) : deleteServiceLabel.get(serviceType)}
          </label>
          <InputNumber
            min={minNodesCount}
            max={myMax}
            defaultValue={1}
            value={nodesNumber}
            onChange={handleChangeNodeCount}
            type="number"
            disabled={confirmLoading}
          />
          {popoverType === 'delete' && (
            <div className="add-remove-cluster-service-popconfirm__note-wrapper">
              <span>
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.deleteServiceDropdown.note" />
              </span>
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.deleteServiceDropdown.message" />
            </div>
          )}
        </div>
      }
      placement={placement}
      icon={null}
      onConfirm={confirm}
      onCancel={cancel}
      okText={
        popoverType === 'add' ? (
          <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.buttons.add" />
        ) : (
          <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.buttons.delete" />
        )
      }
      okButtonProps={{ loading: confirmLoading }}
      cancelText={<FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.buttons.cancel" />}
      cancelButtonProps={{ disabled: confirmLoading }}
      onVisibleChange={handleChangePopConfirmVisibility}
      disabled={popoverType === 'add' ? isAddingDisabled : false}
    >
      {children}
    </Popconfirm>
  );
}

export default AddRemoveClusterServicePopconfirm;
