import React, { useState } from 'react';
import { Tag, Tooltip } from 'antd';
import ClusterServiceControlErrorModal from '../../ClusterServicesControlHelper/clusterServiceControlErrorModal';
import { statusTag } from '../../ClusterServicesControlHelper/useClusterServicesControlHelper';
import { ObservabilityServiceStatus, ServiceStatus } from 'types/cluster';
import { statusTagGreyInfo } from 'utils/cluster';

type StatusTagProps = {
  serviceNode?: string;
  statusName: Map<ServiceStatus, string>;
  status: ServiceStatus | ObservabilityServiceStatus;
  statusMessage?: string;
  warnings?: string[];
  error?: string;
};
function ClusterServicesControlStatusTag({
  serviceNode,
  statusName,
  status,
  statusMessage,
  warnings,
  error,
}: StatusTagProps) {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    if (statusMessage) {
      setIsHovered(true);
    }
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const showModal = () => {
    if (error || (warnings && warnings.length > 0)) setIsErrorModalVisible(true);
  };
  const hideModal = () => {
    setIsErrorModalVisible(false);
  };

  const statusPhase = (status as keyof typeof statusTag) ?? ServiceStatus.CREATING;
  return (
    <>
      <Tooltip title={statusMessage} visible={isHovered} mouseEnterDelay={0.1} mouseLeaveDelay={0.1}>
        <Tag
          className="status-tag"
          icon={statusTag[statusPhase]?.icon ?? statusTagGreyInfo.icon}
          color={statusTag[statusPhase]?.color ?? statusTagGreyInfo.color}
          key={statusPhase}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={showModal}
        >
          {statusName.get(statusPhase) ?? status}
        </Tag>
      </Tooltip>

      <ClusterServiceControlErrorModal
        serviceName={serviceNode || ''}
        type={error ? 'error' : 'warning'}
        errors={(error ? [error] : warnings) || []}
        visible={isErrorModalVisible}
        close={hideModal}
      />
    </>
  );
}

export default ClusterServicesControlStatusTag;
