import './SelfManagedClusterModal.less';

import React, { useState, useContext } from 'react';
import { Form, Button, Col, Input, Row, Select, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { selfManagedClusterNameRules, getClusterTypeIcons, selfManagedClusterNameMax } from 'utils/cluster';
import { Instance, SelfManagedClusterTypes, SubCluster } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { useClusterSizeTypes, useRequestCreateSubCluster, useUpdateSubCluster } from 'hooks/cluster';
import { ReactComponent as IncortaLogo } from 'images/incorta-blue-logo.svg';
import { InstancePlatform } from 'utils/platforms';

export const selfManagedClusterModalFieldNames = {
  name: 'name',
  size: 'size',
  type: 'type',
  fallbackAnalytics: 'fallbackAnalytics',
} as const;

interface SelfManagedClusterModal {
  showModal: boolean;
  onCancel(): void;
  instance: Instance;
  subCluster?: SubCluster;
}

function SelfManagedClusterModal({ showModal, onCancel, instance, subCluster }: SelfManagedClusterModal) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();
  const [form] = Form.useForm<{
    [selfManagedClusterModalFieldNames.name]: string;
    [selfManagedClusterModalFieldNames.size]: number;
    [selfManagedClusterModalFieldNames.type]: SelfManagedClusterTypes;
    [selfManagedClusterModalFieldNames.fallbackAnalytics]: boolean;
  }>();
  const clusterName = instance.name;
  const selfManagedClusterNamePrefix = `${clusterName}-`;
  const selfManagedClusters = instance.subClusters;
  const isSelfManagedClustersHaveSqlx =
    selfManagedClusters?.findIndex((smc) => smc.type === SelfManagedClusterTypes.sqlx) >= 0 ? true : false;
  const isSelfManagedClustersHaveChidori =
    selfManagedClusters?.findIndex((smc) => smc.type === SelfManagedClusterTypes.chidori) >= 0 ? true : false;

  const { validateFields } = form;

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const { mutateAsync: requestCreateSubCluster, isLoading: createSubClusterLoading } = useRequestCreateSubCluster({
    refetchClusterList: true,
  });
  const { mutateAsync: requestUpdateSubCluster, isLoading: updateSubClusterLoading } = useUpdateSubCluster({
    refetchClusterList: true,
  });

  const { data: clusterSizesResult, isLoading: isClusterSizesResultLoading } = useClusterSizeTypes({
    currentPlatform: instance.platform as InstancePlatform,
    clusterCode: instance.k8sClusterCode,
  });
  const availableSizes = clusterSizesResult?.data.sizes;
  const size = availableSizes?.length === 0 ? null : subCluster?.sizeID ?? availableSizes?.[0]?.id;

  const types = [
    {
      id: 0,
      label: 'Analytics',
      value: SelfManagedClusterTypes.analytics,
      tooltip: '',
      icon: <IncortaLogo className="self-managed-cluster-modal__type-select-item" />,
    },
    {
      id: 1,
      label: 'Failover Analytics',
      value: SelfManagedClusterTypes.fallbackanalytics,
      tooltip: '',
      icon: <IncortaLogo className="self-managed-cluster-modal__type-select-item" />,
    },
    {
      id: 2,
      label: 'Advanced SQL Warehouse',
      value: SelfManagedClusterTypes.sqlx,
      tooltip: '',
      icon: <i className="self-managed-cluster-modal__type-select-item sqlx">SQLX</i>,
    },
  ];

  function handleClosingModal() {
    form.resetFields();
    onCancel();
  }

  async function handleSubmit(values: any) {
    try {
      await validateFields();
      if (subCluster) {
        // update sub Cluster
        await requestUpdateSubCluster({
          userId: user.uuid,
          clusterName,
          data: { subCluster: subCluster.name, size: values.size },
        });
      } else {
        // create new one
        await requestCreateSubCluster({
          userId: user.uuid,
          clusterName,
          data: {
            type:
              values.type === SelfManagedClusterTypes.fallbackanalytics
                ? SelfManagedClusterTypes.analytics
                : values.type,
            configs: { fallbackAnalytics: values.type === SelfManagedClusterTypes.fallbackanalytics },
            size: values.size,
            name: values.name,
            nodes: 1,
          },
        });
      }
      handleClosingModal();
    } catch {}
  }

  const loading = createSubClusterLoading || updateSubClusterLoading;

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const { name, type, size } = form.getFieldsValue();

    setTimeout(() => {
      if (
        (subCluster && (!type || !size)) ||
        (!subCluster && (!name || !type || !size)) ||
        form.getFieldsError().some((field) => field.errors.length)
      ) {
        setIsSubmitButtonDisabled(true);
      } else {
        setIsSubmitButtonDisabled(false);
      }
    }, 0);
  };

  return (
    <Modal
      title={`${subCluster ? 'Edit' : 'Create a'} Self-Managed Cluster`}
      visible={showModal}
      onCancel={handleClosingModal}
      footer={null}
      width={645}
      centered
      destroyOnClose
    >
      <div className="self-managed-cluster-modal modal-body">
        <p className="self-managed-cluster-modal__desc">
          <FormattedMessage id={'createNewSubClusterModal.description'} />
        </p>

        <Form
          form={form}
          layout="vertical"
          id="ChangeLoadersModal"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          initialValues={{
            [selfManagedClusterModalFieldNames.name]: subCluster?.name,
            [selfManagedClusterModalFieldNames.size]: subCluster?.sizeID,
            [selfManagedClusterModalFieldNames.type]: subCluster?.type,
            [selfManagedClusterModalFieldNames.fallbackAnalytics]: false,
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.name}
                className="self-managed-cluster-modal__name-field"
                label={<FormattedMessage id={'createNewSubClusterModal.nameFieldLabel'} />}
                rules={
                  subCluster
                    ? []
                    : [
                        ...selfManagedClusterNameRules,
                        {
                          validator(_rule, value: string) {
                            const totalLength = selfManagedClusterNamePrefix.length + value.length;
                            if (totalLength > selfManagedClusterNameMax) {
                              return Promise.reject(`Name should not exceed ${selfManagedClusterNameMax} characters`);
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]
                }
                tooltip={{
                  icon: <InfoCircleOutlined />,
                  title: (
                    <FormattedMessage
                      id={
                        [InstancePlatform.AZURE, InstancePlatform.AZURE_SAAS].includes(
                          instance.platform as InstancePlatform
                        )
                          ? 'createNewSubClusterModal.nameFieldHint.azure'
                          : 'createNewSubClusterModal.nameFieldHint.gc'
                      }
                    />
                  ),
                }}
              >
                <Input
                  size="middle"
                  defaultValue={!!subCluster ? subCluster?.name : undefined}
                  disabled={!!subCluster}
                  prefix={!!subCluster ? '' : selfManagedClusterNamePrefix}
                  placeholder={
                    !subCluster
                      ? intl.formatMessage({
                          id: 'createNewSubClusterModal.nameFieldPlaceholder',
                        })
                      : ''
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.size}
                label={<FormattedMessage id="createNewSubClusterModal.sizeFieldLabel" />}
                initialValue={size}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="clusterForm.clusterSizeRequired" />,
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  loading={availableSizes?.length === 0}
                  placeholder={intl.formatMessage({
                    id: 'createNewSubClusterModal.sizeFieldPlaceholder',
                  })}
                  disabled={isClusterSizesResultLoading}
                >
                  {availableSizes?.map((size) => (
                    <Select.Option key={size.id} value={size.id}>
                      {React.createElement(getClusterTypeIcons(size.name, 'fill'), {
                        className: 'fill-icon',
                        style: { fontSize: 14, color: '#99A6B0' },
                      })}{' '}
                      {size.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.type}
                label={<FormattedMessage id="createNewSubClusterModal.typeFieldLabel" />}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="clusterForm.typeFieldError" />,
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  loading={availableSizes?.length === 0}
                  placeholder={intl.formatMessage({ id: 'createNewSubClusterModal.typeFieldPlaceholder' })}
                  className="self-managed-cluster-modal__type-select"
                  dropdownClassName="self-managed-cluster-modal__type-select-dropdown"
                  disabled={!!subCluster}
                >
                  {types?.map((type) => {
                    let isDisabled = false;
                    let isFailover = subCluster && subCluster.configs;
                    let tooltip = '';
                    if (type.value === SelfManagedClusterTypes.sqlx) {
                      isDisabled =
                        isSelfManagedClustersHaveSqlx ||
                        instance.enableBusinessUserNotebook ||
                        !instance.isIncortaXEligible;
                      tooltip = isSelfManagedClustersHaveChidori
                        ? 'A SQLx is already created'
                        : instance.enableBusinessUserNotebook
                        ? 'Business User Notebook is currently enabled, so the Advanced SQL Interface cannot be activated at the same time. This feature will be available soon.'
                        : !instance.isIncortaXEligible
                        ? 'Unlock Advanced SQL Interface: Upgrade your cluster today for access. Enhance your data capabilities now!'
                        : '';
                    } else if (type.value === SelfManagedClusterTypes.chidori) {
                      isDisabled = isSelfManagedClustersHaveChidori;
                    }

                    return (
                      <Select.Option key={type.value} value={type.value} disabled={isDisabled} title={tooltip}>
                        {type.icon}
                        {isFailover ? 'Failover Analytics' : type.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div className="modal-body-footer">
            <Button htmlType="submit" type="primary" disabled={isSubmitButtonDisabled} loading={loading}>
              {subCluster ? (
                <FormattedMessage id={'createNewSubClusterModal.saveButton'} />
              ) : (
                <FormattedMessage id={'createNewSubClusterModal.createButton'} />
              )}
            </Button>
            <Button onClick={handleClosingModal}>
              <FormattedMessage id={'createNewSubClusterModal.cancelButton'} />
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default SelfManagedClusterModal;
