import './SelfManagedScaleClusterModal.less';

import React, { useState, useContext } from 'react';
import { Form, Button, Col, Input, Row, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { selfManagedClusterNameRules, selfManagedClusterNameMax } from 'utils/cluster';
import { Instance, SubCluster } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { useUpdateSubClusterScale } from 'hooks/cluster';
import { InstancePlatform } from 'utils/platforms';

export const selfManagedClusterModalFieldNames = {
  name: 'name',
  size: 'size',
  type: 'type',
  fallbackAnalytics: 'fallbackAnalytics',
  nodes: 'nodes',
} as const;

interface SelfManagedClusterModal {
  showModal: boolean;
  onCancel(): void;
  instance: Instance;
  subCluster?: SubCluster;
}

function SelfManagedClusterModal({ showModal, onCancel, instance, subCluster }: SelfManagedClusterModal) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();
  const [form] = Form.useForm<{
    [selfManagedClusterModalFieldNames.name]: string;
    [selfManagedClusterModalFieldNames.nodes]: number;
  }>();
  const clusterName = instance.name;
  const selfManagedClusterNamePrefix = `${clusterName}-`;

  const { validateFields } = form;

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const { mutateAsync: requestUpdateSubCluster, isLoading: updateSubClusterLoading } = useUpdateSubClusterScale({
    refetchClusterList: true,
  });

  function handleClosingModal() {
    form.resetFields();
    onCancel();
  }

  async function handleSubmit(values: any) {
    try {
      await validateFields();
      if (subCluster) {
        // update sub Cluster
        await requestUpdateSubCluster({
          userId: user.uuid,
          clusterName,
          data: { subCluster: subCluster.name, nodes: values.nodes },
        });
      }
      handleClosingModal();
    } catch {}
  }

  const loading = updateSubClusterLoading;

  const validateNumber = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error('Input is required'));
    }
    const number = parseInt(value, 10);
    if (isNaN(number) || number < 1 || number > 10) {
      return Promise.reject(new Error('Services must be greater than or equal to 1 and less than or equal to 10'));
    }
    return Promise.resolve();
  };

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const { name, nodes } = form.getFieldsValue();

    setTimeout(() => {
      if (
        (subCluster && !nodes) ||
        (!subCluster && (!name || !nodes)) ||
        form.getFieldsError().some((field) => field.errors.length)
      ) {
        setIsSubmitButtonDisabled(true);
      } else {
        setIsSubmitButtonDisabled(false);
      }
    }, 0);
  };

  return (
    <Modal
      title={`Scale Self-Managed Cluster`}
      visible={showModal}
      onCancel={handleClosingModal}
      footer={null}
      width={645}
      centered
      destroyOnClose
    >
      <div className="self-managed-cluster-modal modal-body">
        <p className="self-managed-cluster-modal__desc">
          <FormattedMessage id={'createNewSubClusterModal.scaleDescription'} />
        </p>

        <Form
          form={form}
          layout="vertical"
          id="ChangeLoadersModal"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          initialValues={{
            [selfManagedClusterModalFieldNames.name]: subCluster?.name,
            [selfManagedClusterModalFieldNames.nodes]: subCluster?.nodes,
          }}
        >
          <Row gutter={16}>
            <Col xs={32} sm={18}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.name}
                className="self-managed-cluster-modal__name-field"
                label={<FormattedMessage id={'createNewSubClusterModal.nameFieldLabel'} />}
                rules={
                  subCluster
                    ? []
                    : [
                        ...selfManagedClusterNameRules,
                        {
                          validator(_rule, value: string) {
                            const totalLength = selfManagedClusterNamePrefix.length + value.length;
                            if (totalLength > selfManagedClusterNameMax) {
                              return Promise.reject(`Name should not exceed ${selfManagedClusterNameMax} characters`);
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]
                }
                tooltip={{
                  icon: <InfoCircleOutlined />,
                  title: (
                    <FormattedMessage
                      id={
                        [InstancePlatform.AZURE, InstancePlatform.AZURE_SAAS].includes(
                          instance.platform as InstancePlatform
                        )
                          ? 'createNewSubClusterModal.nameFieldHint.azure'
                          : 'createNewSubClusterModal.nameFieldHint.gc'
                      }
                    />
                  ),
                }}
              >
                <Input
                  size="middle"
                  defaultValue={!!subCluster ? subCluster?.name : undefined}
                  disabled={!!subCluster}
                  prefix={!!subCluster ? '' : selfManagedClusterNamePrefix}
                  placeholder={
                    !subCluster
                      ? intl.formatMessage({
                          id: 'createNewSubClusterModal.nameFieldPlaceholder',
                        })
                      : ''
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={12} sm={6}>
              <Form.Item
                name={selfManagedClusterModalFieldNames.nodes}
                className="self-managed-cluster-modal__nodes-field"
                label={<FormattedMessage id={'createNewSubClusterModal.nodesFieldLabel'} />}
                rules={[{ validator: validateNumber }]}
                tooltip={{
                  icon: <InfoCircleOutlined />,
                  title: <FormattedMessage id={'createNewSubClusterModal.nodesFieldHint'} />,
                }}
              >
                <Input
                  style={{ height: '40px' }}
                  size="middle"
                  defaultValue={!!subCluster ? subCluster?.nodes : undefined}
                  placeholder={
                    !subCluster
                      ? intl.formatMessage({
                          id: 'createNewSubClusterModal.nameFieldPlaceholder',
                        })
                      : ''
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}></Row>

          <div className="modal-body-footer">
            <Button htmlType="submit" type="primary" disabled={isSubmitButtonDisabled} loading={loading}>
              {subCluster ? (
                <FormattedMessage id={'createNewSubClusterModal.saveButton'} />
              ) : (
                <FormattedMessage id={'createNewSubClusterModal.createButton'} />
              )}
            </Button>
            <Button onClick={handleClosingModal}>
              <FormattedMessage id={'createNewSubClusterModal.cancelButton'} />
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default SelfManagedClusterModal;
